import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// components
import { Layout } from "../components/layout/Layout";
// utils
import { Colors } from "../components/utils/colors";

const IndexPage = () => {
  const [check, setCheck] = useState(true);
  const onClickToggle = () => setCheck(!check);
  return (
    <Layout>
      <SSection>
        <SSlide className="slide">
          <h3>XXXに関するお問い合わせ</h3>
          <p>
            サンプルテキストサンプルテキストサンプルテキスト
            <br />
            サンプルテキストサンプルテキスト
          </p>
          <SBtn>
            <Link to="/form01/" data-check-state={!check}>
              フォーム入力に進む
            </Link>
          </SBtn>
          <SLabel>
            <input type="checkbox" onClick={onClickToggle} />
            <button onClick={onClickToggle} tabIndex="-1">
              <i className="material-icons-outlined">done</i>
            </button>
            利用規約に同意します。
          </SLabel>
        </SSlide>
      </SSection>
    </Layout>
  );
};

export default IndexPage;

const SSection = styled.section`
  width: calc(100% - 4rem);
  height: calc(100% - 2rem);
  margin: auto;
  padding: 2rem;
  background-color: ${Colors.light};
  border-radius: 8px;
  @media screen and (max-width: 599px) {
    width: calc(100% - 1rem);
    height: calc(100% - 2rem);
    padding: 1rem;
  }
`;

const SSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
  }
  p {
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 500;
  }
`;

const SBtn = styled.div`
  margin-top: 3rem;
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    padding: 1rem 2rem;
    min-width: 320px;
    border-radius: 8px;
    margin: 0 1rem;
    transition: 0.2s;
    border: 1px solid ${Colors.primary};
    background-color: ${Colors.primary};
    color: ${Colors.light};
    @media screen and (min-width: 600px) {
      &:hover {
        background-color: ${Colors.light};
        color: ${Colors.primary};
      }
    }
    &[data-check-state="false"] {
      border: 1px solid ${Colors.gray};
      background-color: ${Colors.gray};
      color: ${Colors.dark};
      pointer-events: none;
    }
  }
  padding: 0 2rem;

  @media screen and (max-width: 599px) {
    a {
      width: 100%;
      margin: 0;
      &:active {
        background-color: ${Colors.light};
        color: ${Colors.primary};
      }
    }
  }
`;

const SLabel = styled.label`
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 8px;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
  transition: border 0.2s;
  cursor: pointer;
  user-select: none;
  button {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.gray};
    margin-right: 0.5rem;
    border-radius: 8px;
    transition: all 0.2s;
    border: none;
    border: 1px solid ${Colors.gray};
    &:focus {
      outline: none;
      border-color: ${Colors.primary};
    }
    i {
      transition: all 0.2s;
      color: ${Colors.gray};
      transform: scaleX(0);
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    transform: scale(1.7);
    display: inline-flex;
    z-index: 1;
    margin-right: -1.2rem;
    &:checked {
      + button {
        background-color: ${Colors.primary};
        border-color: ${Colors.primary};
        i {
          color: ${Colors.light};
          transform: scaleX(1);
        }
      }
    }
    &:focus {
      + button {
        border-color: ${Colors.primary};
      }
    }
  }
  &:focus {
    outline: none;
    border: 1px solid ${Colors.primary};
  }
`;
